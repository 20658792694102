import { makeStyles } from '@material-ui/core/styles';

const colors = {
  "delete": "rgba(254, 0, 0, 0.8)",
  "edit": "rgba(17, 82, 147, 0.8)",
  "arrow_upward": "rgba(33, 150, 243, 1)",
  "arrow_downward": "rgba(77, 81, 108, 1)",
  "reorder": "rgba(245, 124, 0, 1)",
  "view_week": "rgba(33, 150, 243, 1)",
  "calendar_view_day": "rgba(77, 81, 108, 1)",
  "any": "#272c34"
}

export const useStyles = makeStyles(() => ({
    buttonColor: props => ({
        color: colors[props.icon],
    })
  }));