import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { customGetDataPublic } from 'actions/customAction';
import ItemCard from 'components/card/ItemCard';
import LoadingSpinner from 'components/LoadingSpinner';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showMessageError } from 'services/SweetAlert';
import styles from 'styles/cardInformation';

const ValidarCredencialFiscalInformaticoQR = () => {
    const { idOP, noBoleta } = useParams();
    const dispatch = useDispatch()
    const classes = styles();

    const [loadingRequest, setLoadingRequest] = useState(false);
    const [showInfoCandidato, setShowInfoCandidato] = useState(false);
    const [datosCandidato, setDatosCandidato] = useState([]);
    const [errorMessages, setErrorMessages] = useState("");

    useEffect(() => {
        obtenerInformacionCandidato()
    }, []);

      // TO GET DOCUMENTS OF THE ASSEMBLY
    const obtenerInformacionCandidato = async () => {

        setLoadingRequest(true);
        setDatosCandidato(false);

        const dataCustomFetch = { controller: "formularios", action: `validarFiscal/${idOP}/${noBoleta == "null" ? 0 : noBoleta}`, }

        const response = await dispatch(customGetDataPublic(dataCustomFetch));

        setLoadingRequest(false);

        if(response?.error){
            setShowInfoCandidato(true);
            return setErrorMessages(response?.error?.message || "Inconvenientes para obtener información del fiscal informático")
        }

        if(response?.data?.length === 0 ){
            setShowInfoCandidato(true);
            return setErrorMessages(response?.error?.message || 'NO SE PUEDE OBTENER INFORMACIÓN DEL FISCAL INFORMÁTICO, INTENTE NUEVAMENTE')
        }
        if(!response?.data){
            setShowInfoCandidato(true);
            return showMessageError('NO SE PUEDE OBTENER INFORMACIÓN DEL FISCAL INFORMÁTICO, INTENTE NUEVAMENTE','')
        }

       setDatosCandidato(response?.data[0]);
       setShowInfoCandidato(true);
    }

    if(loadingRequest) return <LoadingSpinner open={loadingRequest}/>

    return (
        <Card className={classes.root}>

            <CardContent>
            {showInfoCandidato && (
                <>
                    {datosCandidato?.idOP ? (
                        <>
                         <h1 className={classes.titleAlerta}>Módulo de Credenciales de fiscales informáticos de las Organizaciones Poliíticas</h1>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={4} wrap={"wrap"} style={{margin: '5px 0px'}}  >
                            <Grid item xs={12}>
                                <h3 className={classes.titleAlerta}>Información del fiscal informático</h3>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <ItemCard  title={'Organización Política: '} label={datosCandidato?.objetoOrgnaizacion || ""}/>
                                <ItemCard  title={'Nombre completo: '} label={datosCandidato?.nombreCompleto || datosCandidato?.extranjero?.nombreExtranjero || ""}/>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <ItemCard  title={"Rango: "} label={datosCandidato?.cargo || ""}/>
                                <ItemCard  title={"Nivel: "} label={"NACIONAL"}/>
                            </Grid>
                        </Grid>
                        </>
                    ) : ( <>
                            <h1 className={classes.titleAlerta}>Módulo de Credenciales de fiscales informáticos de las Organizaciones Poliíticas</h1>
                            <Typography variant='h6' style={{textAlign: 'center', margin: '30px 0px', lineHeight: "27px"}}  > {errorMessages || ""} EN SU ORGANIZACIÓN POLÍTICA </Typography>
                        </>)
                    }
                </>
            )}
            </CardContent>
            <div className={classes.lineaDegradadaBottom}></div>
        </Card>
    )
}

export default ValidarCredencialFiscalInformaticoQR;