import ApiGenericServicesData from 'services/ApiGenericServicesData';

export default class ConstanciaAfiliacionAPartidoPoliticoOrganizacionesPoliticas extends ApiGenericServicesData{
    constructor(){
        super("constanciaAfiliacionAPartidoPoliticoOrganizacionesPoliticas");
    }

    create(data) {      return this.customPOST("create",data)}

    openFile(id) {         return this.customGET("openFile/"+id);    }    

    rechazar(datos){return this.customPATCH("rechazar/"+datos.id +"/"+datos.observacion);  }

    firmarDocumento(datos) {return this.customPOST("firmarDocumento",datos)}

    delegateRequest (request) {return this.customPOST("delegateRequest", request) }

}