import ApiGenericServicesData from 'services/ApiGenericServicesData';
import UserSecurity from 'services/UserSecurity';
import User from 'services/User';
import SolicitudUsuario from 'services/SolicitudUsuario';


import ConstanciaAfiliacionAPartidoPoliticoOrganizacionesPoliticas from 'services/ConstanciaAfiliacionAPartidoPoliticoOrganizacionesPoliticas';

import ConsultaDatosAfiliacion from 'services/ConsultaDatosAfilicacion';

class ApiServices{
    constructor(){
        this.user = new User();
        this.userSecurity=new UserSecurity();
        this.solicitudUsuario = new SolicitudUsuario();

        this.constanciaAfiliacionAPartidoPoliticoOrganizacionesPoliticas = new ConstanciaAfiliacionAPartidoPoliticoOrganizacionesPoliticas();

        this.departamento = new ApiGenericServicesData("departamento");
        this.municipio = new ApiGenericServicesData("municipio");
        this.organizacionPolitica = new ApiGenericServicesData("organizacionPolitica");
        this.asambleas = new ApiGenericServicesData("asambleas");
        this.candidatosAsamblea = new ApiGenericServicesData("candidatosAsamblea");
        this.formularios = new ApiGenericServicesData("formularios");
        this.documento = new ApiGenericServicesData("documento");

        this.consultaDatosAfiliacion = new ConsultaDatosAfiliacion();
        this.representantes = new ApiGenericServicesData('representates')
        this.empadronamiento = new ApiGenericServicesData('empadronamiento')
        this.reportesInscripcionCandidatos = new ApiGenericServicesData('reportesInscripcionCandidatos')
        this.afiliados = new ApiGenericServicesData('afiliados')
        this.candidatosICO = new ApiGenericServicesData('candidatosICO')
        this.estadosCandidatos = new ApiGenericServicesData('estadosCandidatos')
        this.diputadosDistritales = new ApiGenericServicesData('diputadosDistritales')
        this.sesionComite = new ApiGenericServicesData('sesionComite')
        this.candidatosSesion = new ApiGenericServicesData('candidatosSesion')
        this.comiteCivico = new ApiGenericServicesData('comiteCivico')
        this.convenioCoalicion = new ApiGenericServicesData('convenioCoalicion')
        this.catalogoDistritos = new ApiGenericServicesData('catalogoDistritos')
    }
}

export default new ApiServices();

