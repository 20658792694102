import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { customGetDataPublic } from 'actions/customAction';
import ItemCard from 'components/card/ItemCard';
import LoadingSpinner from 'components/LoadingSpinner';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showMessageError } from 'services/SweetAlert';
import styles from 'styles/cardInformation';

const ValidarCandidatoQR = () => {
    const { idFormulario, noBoleta } = useParams();
    const dispatch = useDispatch()
    const  classes = styles();

    const [loadingRequest, setLoadingRequest] = useState(false);
    const [showInfoCandidato, setShowInfoCandidato] = useState(false);
    const [datosCandidato, setDatosCandidato] = useState([]);
    const [datosCandidatosP2, setDatosCandidatP2] = useState([]);
    const [errorMessages, setErrorMessages] = useState("");

    useEffect(() => {
        obtenerInformacionCandidato()
    }, []);

      // TO GET DOCUMENTS OF THE ASSEMBLY
    const obtenerInformacionCandidato = async () => {
        setLoadingRequest(true);
        setDatosCandidato(false);

        const dataCustomFetch = { controller: "formularios", action: `validarCandidato/${idFormulario}/${noBoleta}`, }

        const response = await dispatch(customGetDataPublic(dataCustomFetch));

        setLoadingRequest(false);

        if(response?.error){
            setShowInfoCandidato(true);
            return setErrorMessages("INCONVENIENTES PARA OBTENER INFORMACIÓN DEL CANDIDATO")
        }

        if(response?.data?.length === 0 ){
            setShowInfoCandidato(true);
            return setErrorMessages('NO SE PUEDE OBTENER INFORMACIÓN DEL CANDIDATO, INTENTE NUEVAMENTE')
        }
        if(!response?.data){
            setShowInfoCandidato(true);
            return showMessageError('','NO SE PUEDE OBTENER INFORMACIÓN DEL CANDIDATO, INTENTE NUEVAMENTE')
        }

       setDatosCandidato(response?.data[0]);
       setDatosCandidatP2(response?.data[1]);
       setShowInfoCandidato(true);
    }

    if(loadingRequest) return <LoadingSpinner open={loadingRequest}/>

    return (
        <Card className={classes.root}>

            <CardContent>
            {showInfoCandidato && (
                <>
                    {datosCandidato?.idFormulario ? (
                        <>
                         <h1 className={classes.titleAlerta}>Módulo de Registro Electrónico de Inscripción de Candidatos -ICO-</h1>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={4} wrap={"wrap"} style={{margin: '5px 0px'}}  >
                            <Grid item xs={12}>
                                <h3 className={classes.titleAlerta}>Información del Candidato Inscrito</h3>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <ItemCard  title={'Organización Política'} label={datosCandidato?.organizacionPolitica?.nombreOp || ""}/>
                                <ItemCard  title={'Siglas'} label={datosCandidato?.organizacionPolitica?.siglas || ""}/>
                                <ItemCard  title={"Tipo de Formulario"} label={datosCandidato?.tipoInscripcion?.tipoInscripcion || ""}/>
                                <ItemCard  title={"Estado del Candidato"} label={datosCandidatosP2?.idEstadoCandidato == 6 ? "INSCRITO": " " || "" }/>
                            </Grid>

                            <Grid item xs={12} md={6}>
                            <ItemCard  title={"Nombre del Candidato"} label={datosCandidatosP2?.padron?.nombreCompleto || ""}/>
                                <ItemCard  title={" Cargo"} label={datosCandidatosP2?.cargoPolitico?.cargoPolitico || ""}/>
                                {datosCandidato?.department && (
                                    <ItemCard  title={'Departamento '} label={datosCandidato?.department?.name || ""}/>
                                )}
                                {datosCandidato?.municipioResidencia && (
                                    <ItemCard  title={'Municipio'} label={datosCandidato?.municipioResidencia?.name || ""}/>
                                )}
                            </Grid>
                        </Grid>
                        </>
                    ) : ( <>
                            <h1 className={classes.titleAlerta}>Módulo de Registro Electrónico de Inscripción de Candidatos -ICO-</h1>
                            <Typography variant='h6' style={{textAlign: 'center', margin: '30px 0px', lineHeight: "27px"}}  > {errorMessages || ""} EN SU ORGANIZACIÓN POLÍTICA </Typography>
                        </>)
                    }
                </>
            )}
            </CardContent>
            <div className={classes.lineaDegradadaBottom}></div>
        </Card>
    )
}

export default ValidarCandidatoQR;