import React, { useEffect } from 'react';
import {Button, Card, CardContent, Dialog, Grid, Slide, Typography} from '@material-ui/core/';
import Icon from '@material-ui/core/Icon';
import useStyles from './styles';
import useButtonStyles from 'styles/button';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Modal({title, message, open, closeModal, titleButton, titleButtonClose, disableBackdropClick, disableEscapeKeyDown, actionAdd}) {
    let buttonYes = React.createRef();
    const classes = useStyles();
    const buttonStyles = useButtonStyles();

    useEffect(() => {
        if (buttonYes.current!==null)
            buttonYes.current.focus();
    });

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={(_, reason)=> {
                    if(reason === 'escapeKeyDown' && disableEscapeKeyDown) {
                        closeModal()
                    }

                    if(reason === 'backdropClick' && disableBackdropClick) {
                        closeModal()
                    }
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Card >
                    <CardContent >
                        <Grid  container justifyContent="center" alignItems="center" spacing={1} >
                            <Icon color={"primary"} className={buttonStyles.icon}>
                                errorOutline
                            </Icon>
                            <Typography className={classes.title} >
                                {title}
                            </Typography>
                            <Typography className={classes.description} >
                                {message}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                            <Button variant="contained" onClick={()=>actionAdd(true)} className={buttonStyles.button} ref={buttonYes}>{titleButton}</Button>
                            <Button variant="contained" onClick={()=>closeModal(false)} className={buttonStyles.buttonCancel} >{titleButtonClose}</Button>
                        </Grid>
                    </CardContent>
                </Card>
            </Dialog>
        </div>
    );
}

Modal.defaultProps = {
    title: "Mensaje de confirmación",
    titleButton: "Sí, Agregar",
    titleButtonClose: "No, Cancelar",
    disableBackdropClick: false,
    disableEscapeKeyDown: false
};

export default Modal;