import {actionNames} from "constants/actionNames";

const initialState = {
    candidateStates: [],
    presiAndViceCandidateStates: [],
    deputiesNationalListStates: [],
    deputiesToTheParliamentStates: [],
    deparmentCandidateStates: [],
    municipalityCandidateStates: [],
    civicCommitteCandidateStates: [],
    boardOfDirectorCandidateStates: [],
    dataCandidateForRegistration: [],
    valueTabPanel: 0,
    id_form_pv: 0,
    id_form_dp: 0,
    id_form_dn: 0,
    id_form_dd: 0,
    id_form_cm: 0,
    id_form_cm_cce: 0,
    departments: [],
    districts: [],
    catalogos:{municipalities:[]},
};

export default (state = initialState, action ) => {
    switch (action.type) {
        case actionNames.GET_DEPARTMENTS:
            return {
                ...state,
                departments: action.payload,
            }
        case actionNames.GET_DISTRICTS:
            return {
                ...state,
                districts: action.payload,
            }
        case actionNames.GET_MUNICIPALITIES:
            let newState = Object.assign({}, state);
            newState.catalogos.municipalities= state.catalogos.municipalities.length > 0 ? state.catalogos.municipalities.concat(action.payload) : action.payload;
            return newState;
        case actionNames.GET_CANDIDATE_STATE:
            return {
                ...state,
                candidateStates: action.payload,
            }
        case actionNames.GET_DEPARMENT_CANDIDATE_STATE:
            return {
                ...state,
                deparmentCandidateStates: action.payload,
            }
        case actionNames.GET_MUNICIPALITY_CANDIDATE_STATE:
            return {
                ...state,
                municipalityCandidateStates: action.payload,
            }
        case actionNames.CLEAN_DEPARMENT_CANDIDATE_STATE:
            return {
                ...state,
                deparmentCandidateStates: action.payload,
            }
        case actionNames.CLEAN_MUNICIPALITY_CANDIDATE_STATE:
            return {
                ...state,
                municipalityCandidateStates: action.payload,
            }
        case actionNames.GET_CANDIDATE_CIVIC_COMMITTE_STATE:
            return {
                ...state,
                civicCommitteCandidateStates: action.payload,
            }
        case actionNames.GET_CANDIDATE_BOARD_OF_DIRECTORS_STATE:
            return {
                ...state,
                boardOfDirectorCandidateStates: action.payload,
            }
        case actionNames.SET_VALUE_TAB_PANEL:
            return {
                ...state,
                valueTabPanel: action.payload,
            }
        case actionNames.SET_DATA_CANDIDATE_FOR_REGISTRATION:
            return {
                ...state,
                dataCandidateForRegistration: action.payload,
            }
        case actionNames.SET_PRESI_AND_VICE_CANDIDATE_STATE:
            return {
                ...state,
                presiAndViceCandidateStates: action.payload,
            }
        case actionNames.SET_DEPUTIES_NATIONAL_LIST_STATE:
            return {
                ...state,
                deputiesNationalListStates: action.payload,
            }
        case actionNames.SET_DEPUTIES_TO_THE_PARLIAMENT_STATE:
            return {
                ...state,
                deputiesToTheParliamentStates: action.payload,
            }
        case actionNames.SET_ID_FORM_PV:
            return {
                ...state,
                id_form_pv: action.payload,
            }
        case actionNames.SET_ID_FORM_DP:
            return {
                ...state,
                id_form_dp: action.payload,
            }
        case actionNames.SET_ID_FORM_DN:
            return {
                ...state,
                id_form_dn: action.payload,
            }
        case actionNames.SET_ID_FORM_DD:
            return {
                ...state,
                id_form_dd: action.payload,
            }
        case actionNames.SET_ID_FORM_CM:
            return {
                ...state,
                id_form_cm: action.payload,
            }
        case actionNames.SET_ID_FORM_CM_CCE:
            return {
                ...state,
                id_form_cm_cce: action.payload,
            }
        default:
            return state;
    }
}