import { makeStyles } from "@material-ui/core";

export default makeStyles( theme => ({
    title: {
      fontSize: "1.8rem",
      fontWeight: 600,
      textAlign: 'center',
      color: '#595959',
      marginTop: 40,
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        fontSize: 22,
      },
    },
    description: {
      fontWeight: 600,
      fontSize: '1.4rem',
      lineHeight: '27px',
      color: '#545454',
      textAlign: 'center',
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    icon : {
      fontSize: 80, display: 'block',
      "background":"linear-gradient(45deg, #205690 30%, #3890EE 90%)",
      "border":"0px", "borderRadius":"100%",
      "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white",
  },
}));