const downloadPDFFromStringBase64=function(data,name) {
    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement("a");
    const fileName = name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

const orderArray = function(array, attribute) {
  return array.sort( function(a, b) {
    if(a[attribute] < b[attribute]) return -1;
    if(a[attribute] > b[attribute]) return 1;
    return 0;
  });
}

//le damos formato a las fechas yy-mm-dd
const splitDate = function (date) {
    if (date !== undefined) {
      if (date !== null) {
        let arr1 = date.split("-");
        if (arr1.length === 1) return date;
        let arr2 = arr1[2].split("T");
        return arr2[0] + "/" + arr1[1] + "/" + arr1[0];
      }
    }
  };

  const dateFormatGeneral = (date, dataToReturn = "") => {
    if (date) {
      if (typeof date == "string") {
        return splitDate(date);
      }

      const start = new Date(`${date?.toDateString()}`);
      const options = { year: "numeric", month: "numeric", day: "2-digit" };
      return start.toLocaleDateString("es-ES", options);
    }
    return dataToReturn;
  };

export const functions={
        "downloadPDFFromStringBase64":downloadPDFFromStringBase64,
        "orderArray": orderArray,
        splitDate,
        dateFormatGeneral,
};

