import React from 'react';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import ButtonBack from 'components/Table/Buttonback';
import ButtonAction from 'components/Table/ButtonAction';
import ModalConfirmation from 'components/ModalConfirmation';
import TableData from './TableData';
import ActionList from './ActionList';
import {styles} from './Style';
import './styles.css'

function Table(props) {
    const { classes, showPagination, pageSize } = props;
    const [modalParams, setModalParams] = React.useState({'open':false,'handler':null,'data':null});
    const tableData =  new TableData();
    const actionList = new ActionList(props,setModalParams);

    const closeModal=(option)=>{
        setModalParams({'open':false});
        if (option) modalParams.handler(modalParams.data);
    }

    const getdata= async (query,header,service)=>{
        tableData.setQuery(query);
        tableData.setHeader(header);
        tableData.setService(service);
        if(props.obtenerDatos) {
            let prueba = await tableData.getPromiseResponse();
            props.obtenerDatos(prueba);
            return prueba;
        }
        return tableData.getPromiseResponse();
    }

if (props.data===[]) return(null)
  else return (
    <div className={classes.root}>
        <div className={classes.container}>
                {/* abrir el modal de confirmación */}
            <ModalConfirmation open={modalParams.open} data={modalParams.data} handler={modalParams.handler} closeModal={closeModal} message={modalParams.message}/>
            <MaterialTable
                localization={{
                    toolbar: {searchPlaceholder: "Buscar",searchTooltip: "Buscar "},
                    pagination:{labelRowsSelect:"Registros",labelRowsPerPage:"Filas por pagina",
                                labelDisplayedRows: 'Registros {from} al {to} de {count}',
                                previousTooltip:'Pagina anterior', nextTooltip:'Página siguiente', lastTooltip:'Última página', firstTooltip:'Primera página'},
                    body: {deleteTooltip: "Eliminar",emptyDataSourceMessage: "No existen registros"},
                    header:{ actions: 'Opciones'}
                }}

                title={props.title!==undefined ? props.title: "Listado de registros"}
                columns={props.header}
                data = {(props.service!== undefined) ? query=>getdata(query,props.header, props.service) : props.data}
                detailPanel={ (props.detailPanel!==undefined) ?  props.detailPanel : []}
                actions={actionList.getList()}
                options={{
                    debounceInterval: 1500,
                    pageSize,
                    pageSizeOptions: props.pageSizeOptions,
                    toolbar: true,
                    paging: true,
                    filtering: props.filtering,
                    actionsColumnIndex: -1,
                    search: props.searchValue,
                    headerStyle: {
                        backgroundColor: '#205690',
                        color: '#FFF'
                    },
                }}
                components={{
                    Action: propsAction => { 
                        if (propsAction.action.position==="toolbar"){
                            return (
                                <ButtonBack     icon={propsAction.action.icon} tooltip={propsAction.action.tooltip}
                                                onClick={propsAction.action.onClick}>
                                </ButtonBack>
                            )
                        }else{
                            let element= propsAction.action.action(propsAction.data);
                            return (
                                    <ButtonAction   tooltip={element.tooltip}
                                                    onClick={element.onClick}
                                                    icon={element.icon}/>)
                        }
                    },
                    Pagination: propsPagination => {
                        if(showPagination) {
                            return (
                                <TablePagination
                                    {...propsPagination}
                                    SelectProps={{
                                    style:{
                                        fontSize: 14,
                                    }
                                    }}
                                />
                            )
                        }
                        return null
                    }
                }}
                className={classes.table}
            >
            </MaterialTable>
        </div>
    </div>
  );
}

Table.defaultProps = {
    showPagination: true,
    pageSize: 10,
    searchValue: false,
    pageSizeOptions: [5, 10, 20, 30 ,50, 75, 100 ],
    filtering: false,
}


export default withStyles(styles)(Table);