
import ApiGenericServicesData from 'services/ApiGenericServicesData';

export default class User extends ApiGenericServicesData{
    constructor(){
        super("solicitudUsuario");
    }
    create(data){        return this.customPOST("create",data);    }

    openFile(data) {    return this.customGET("openFile/"+data.id+"/"+data.folderName);    }  

    autorizar(data) {   return this.customPATCH("autorizar/"+data.id+"/"+data.password);    }
    
    rechazar(data){   return this.customPATCH("rechazar/"+data.id+"/"+data.observacion);  }

    validarDatos (cui) {    return this.customGET("validarDatosAfiliacion/"+cui);  }
}