//React
import React from 'react';
import clsx from 'clsx';
import {  useTheme } from '@material-ui/core/styles';
import { IconButton, Drawer, CssBaseline, AppBar , Toolbar, Typography, Divider, Fab } from '@material-ui/core';
import { Route,  Switch, Link } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import VerticalMenu from 'components/VerticalMenu';
import {connect } from "react-redux";
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
import {useStyles} from './Style';
import ValidarCandidatoQR from 'pages/ValidarCandidato';
import ValidarCredencialFiscalInformaticoQR from 'pages/validarCredencialFiscalInformaticoQR';
import ValidarInformacionQR from 'pages/ValidarInscripcion';

const Menu=()=> {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerClose=()=> {
        setOpen(false);
    }

  return (

    <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed"
                className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
                })}>
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" className={classes.title} >
                    <Link className={classes.appTitle} to="/">
                        <img className={classes.appIcon} src="/assets/WhiteIcon.svg"  alt="fireSpot"/>
                        Inscripción de Candidatos -ICO-
                    </Link>
                </Typography>
            </Toolbar>
        </AppBar>

        <Drawer className={classes.drawer}  variant="persistent"    anchor="left"
                open={open} classes={{  paper: classes.drawerPaper,}}
        >
            <div className={classes.drawerHeader}>
                <Fab color="primary" aria-label="add" className={classes.fab}>
                    <img className={classes.image}src={"/svgMenu/luz.svg"} alt="Italian Trulli"></img>
                </Fab>
                Bienvenido
                <IconButton className={classes.iconMenu}  onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />
                <VerticalMenu/>
            <Divider variant="middle"/>
        </Drawer>

        <main className={clsx(classes.content, {  [classes.contentShift]: open,})}>
            <Switch>
                <Route exact path="/formularios/validarCandidato/:idFormulario/:noBoleta" render={() => <ValidarCandidatoQR />}></Route>
                <Route exact path="/formularios/validarInscripcion/:idFormulario/:idOP" render={() => <ValidarInformacionQR />}></Route>
                <Route path="/formularios/validarFiscal/:idOP/:noBoleta" render={() => <ValidarCredencialFiscalInformaticoQR />}></Route>
            </Switch>
        </main>

    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
