import { ACCESS_TOKEN, actionNames } from '../constants';
import SearchCriteriaClass from 'services/SearchCriteriaClass'; 
import OrderCriteriaClass from 'services/OrderCriteriaClass'; 

export default class ApiGenericServices{
    constructor(moduleName,request){
        this.moduleName=moduleName;
        this.searchCriteria = new SearchCriteriaClass();
        this.initSearchCriteria = new SearchCriteriaClass();
        this.orderCriteria = new OrderCriteriaClass();
        this.initOrderCriteria = new OrderCriteriaClass();
        this.request= request;
        this.isPublic = false;
        this.withRedis =  null;
    }
    setIsPublic(isPublic){ this.isPublic=isPublic }
    setWithRedis(withRedis) {this.withRedis = withRedis}

    pageRegister(page,size) {
        if(!sessionStorage.getItem(ACCESS_TOKEN)) {
            return Promise.reject("Acceso denegado");
        }
           
        let searchCriteriaParam =    (this.searchCriteria!== undefined)   ? "searchCriteria="+encodeURIComponent(JSON.stringify(this.searchCriteria.get())) : null;
        let orderCriteriaParam =     (this.orderCriteria!== undefined)    ? "orderCriteria="+encodeURIComponent(JSON.stringify(this.orderCriteria.get())) : null;
        let url = "/"+this.moduleName+"/list/"+page+"/"+size+"?"+searchCriteriaParam+"&"+orderCriteriaParam;
        
        return this.request({
            url: url,
            method: 'GET'
            });
    }

    listRegisterCriteria() {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
       
        let searchCriteriaParam =    (this.searchCriteria!== undefined)   ? "searchCriteria="+encodeURIComponent(JSON.stringify(this.searchCriteria.get())) : null,
            orderCriteriaParam =     (this.orderCriteria!== undefined)    ? "orderCriteria="+encodeURIComponent(JSON.stringify(this.orderCriteria.get())) : null;

            const url = `/${this.moduleName}/${this.withRedis ? `redis/list?` : `list?`}${searchCriteriaParam}&${orderCriteriaParam}`;

        return this.request({
            url: url,
            method: 'GET',
            witRedis: this.withRedis
        });
    } 

    customListWithParameter(_action, parameter, electionTypes) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }

        let searchCriteriaParam = (this.searchCriteria !== undefined) ? "searchCriteria="+encodeURIComponent(JSON.stringify(this.searchCriteria.get())) : null,
        orderCriteriaParam =     (this.orderCriteria!== undefined)    ? "orderCriteria="+encodeURIComponent(JSON.stringify(this.orderCriteria.get())) : null;

        const url = `/${this.moduleName}/${this.withRedis ? `redis/list/` : `list/`}${parameter}/${electionTypes}?${searchCriteriaParam}&${orderCriteriaParam}`;

        return this.request({
            url: url,
            method: 'GET',
            witRedis: this.withRedis
        });
    }

    listRegister() {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }

        const url = `/${this.moduleName}/${this.withRedis ? `redis/list` : `list` }`;

        return this.request({
            url,
            method: 'GET',
            witRedis: this.withRedis
        });
    }

    getDocumentRegister(action,documentName, idRegistro) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
        return this.request({
            url: `/${this.moduleName}/${action}/${documentName}/${idRegistro}`,
            method: 'GET'
        });
    }

    listRegisterPublic() {
        return this.request({
            url: "/"+this.moduleName+"/list",
            method: 'GET'
        });
    }

    createRegister(data) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
        return this.request({
            url: "/"+this.moduleName+"/create",
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    createReport(data, method = "") {

        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
        return this.request({
            url: "/"+this.moduleName+"/reporte",
            url: `/${this.moduleName}/reporte/${method ? method : ""}`,
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    createRegisterPublic(data) {
        return this.request({
            url: "/"+this.moduleName+"/create",
            method: 'POST',
            body: JSON.stringify(data)
        });
    }
    
    updateRegister(data) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
        return this.request({
            url: "/"+this.moduleName+"/update",
            method: 'PUT',
            body: JSON.stringify(data)
        });
    }
    
    deleteRegister(params) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
        
        return this.request({
            url: "/"+this.moduleName+"/delete/"+params.id,
            method: 'DELETE'
        });
    }
    
    enableRegister(params) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
        
        return this.request({
            url: "/"+this.moduleName+"/enable/"+params.id,
            method: 'PATCH'
        });
    }
    
    disableRegister(params) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
        
        return this.request({
            url: "/"+this.moduleName+"/disable/"+params.id,
            method: 'PATCH'
        });
    }

    customGET(methodName) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
        let searchCriteriaParam =    (this.searchCriteria!== undefined)   ? "searchCriteria="+encodeURIComponent(JSON.stringify(this.searchCriteria.get())) : null;
        let orderCriteriaParam =     (this.orderCriteria!== undefined)    ? "orderCriteria="+encodeURIComponent(JSON.stringify(this.orderCriteria.get())) : null;
        let url = "/"+this.moduleName+"/"+methodName+"?"+searchCriteriaParam+"&"+orderCriteriaParam;

        return this.request({
            url: url,
            method: 'GET',
            witRedis: this.withRedis
        });
    }

    customGETAction(methodName, action) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
        let searchCriteriaParam =    (this.searchCriteria!== undefined)   ? "searchCriteria="+encodeURIComponent(JSON.stringify(this.searchCriteria.get())) : null;
        let orderCriteriaParam =     (this.orderCriteria!== undefined)    ? "orderCriteria="+encodeURIComponent(JSON.stringify(this.orderCriteria.get())) : null;
        // let url = "/"+this.moduleName+"/"+methodName+"/"+action+"?"+searchCriteriaParam+"&"+orderCriteriaParam;
        let url = `/${this.moduleName}/${this.withRedis ? `redis/${methodName}` : `${methodName}` }/${action}?${searchCriteriaParam}&${orderCriteriaParam}`;


        return this.request({
            url: url,
            method: 'GET',
            witRedis: this.withRedis
        });
    }

    customGETpublic(methodName) {
        let searchCriteriaParam =    (this.searchCriteria!== undefined)   ? "searchCriteria="+encodeURIComponent(JSON.stringify(this.searchCriteria.get())) : null;
        let orderCriteriaParam =     (this.orderCriteria!== undefined)    ? "orderCriteria="+encodeURIComponent(JSON.stringify(this.orderCriteria.get())) : null;
        let url = "/"+this.moduleName+"/"+methodName+"?"+searchCriteriaParam+"&"+orderCriteriaParam;

        return this.request({
            url: url,
            method: 'GET'
        });
    }
 
    customPUT(methodName) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
    
        return this.request({
            url: "/"+this.moduleName+"/"+methodName,
            method: 'PUT'
        });
    }

    customPOST(methodName,data) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
    
        return this.request({
            url: "/"+this.moduleName+"/"+methodName,
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    customPATCH(methodName, data) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }
        
        return this.request({
            url: "/"+this.moduleName+"/"+methodName,
            method: 'PATCH',
            body: JSON.stringify(data)
        });
    }

    customPATCHTwo(action, methodName, data) {
        if(!sessionStorage.getItem(ACCESS_TOKEN) && !this.isPublic) {
            return Promise.reject("Acceso denegado");
        }

        return this.request({
            url: "/"+this.moduleName+"/"+action+"/"+methodName,
            method: 'PATCH',
            body: JSON.stringify(data)
        });
    }

    customListWithParameterPUBLIC(_action, parameter, electionTypes) {

        const url = `/${this.moduleName}/${parameter}/${electionTypes}`;

        return this.request({
            url: url,
            method: 'GET',
        });
    }
}


