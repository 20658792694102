import types from "actions/representatives/types";

const initialState = {
    representatives: [
        {id: 1, cui: '46546464', fullName: 'Torito ', email: 'torito@gmail.com', phone: '2351741', address: "zona 1"},
        {id: 2, cui: '7979874', fullName: 'Juan de lEon ', email: 'juan@gmail.com', phone: '8785161', address: "zona 15"},
    ],
    representative : {
        id: 1, cui: '', firstName: '', secondName: '', lastName: '', secondSurname: '', email: '', phone: '', address: "",
    }
};

export default (state = initialState, action ) => {
    switch (action.type) {
        case types.SAVE_REPRESENTATIVES:
            console.log(action);
            return {
                ...state,
                form: action.payload.representatives,
            };

        case types.SET_REPRESENTATIVE:
            console.log(action);
            return {
                ...state,
                representative : action.payload
            }
        default:
            return state;
    }
}