import React from 'react';
import {Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from 'styles/cardInformation';
import { AssignmentOutlined } from '@material-ui/icons';

const ItemCard = ({title, label, Icon}) => {
  const classes = styles();
  return (
    <Grid container className={classes.informationContainer}>
        <Grid item xs={4}>
            <Grid container >
                <Grid item xs={11}>
                    <Typography className={classes.title} >{title}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Icon className={classes.icon} />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={8}>
            <Typography className={classes.description} >{label ? label : ""}</Typography>
        </Grid>
    </Grid>
  );
}
ItemCard.defaultProps = {
    Icon : AssignmentOutlined
}

ItemCard.propTypes = {
    title: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default ItemCard;