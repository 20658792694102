import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { customGetDataPublic } from 'actions/customAction';
import ItemCard from 'components/card/ItemCard';
import LoadingSpinner from 'components/LoadingSpinner';
import { functions } from 'constants/functions';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showMessageError } from 'services/SweetAlert';
import styles from 'styles/cardInformation';

const ValidarInformacionQR = () => {
    const { idFormulario, idOP } = useParams();
    const dispatch = useDispatch()
    const  classes = styles();

    const [loadingRequest, setLoadingRequest] = useState(false);
    const [showInfoCarataula, setShowInfoCaratula] = useState(false);
    const [datosCaratula, setDatosCaratula] = useState([]);
    const [errorMessages, setErrorMessages] = useState("");

    useEffect(() => {
        obtenerInformacionCaratula()
    }, []);

      // TO GET DOCUMENTS OF THE ASSEMBLY
    const obtenerInformacionCaratula = async () => {
        setLoadingRequest(true);
        setDatosCaratula(false);

        const dataCustomFetch = { controller: "formularios", action: `validarInscripcion/${idFormulario}/${idOP}`, }

        const response = await dispatch(customGetDataPublic(dataCustomFetch));

        setLoadingRequest(false);

        if(response?.error){
            setShowInfoCaratula(true);
            return setErrorMessages(response?.error?.message || "Inconvenientes para obtener información de la cáratula")
        }

        if(response?.data?.length === 0 ){
            setShowInfoCaratula(true);
            return setErrorMessages(response?.error?.message || 'EL NÚMERO DE FORMULARIO NO EXISTE PARA ESTE TIPO DE INSCRIPCIÓN EN SU ORGANIZACIÓN POLÍTICA')
        }
        if(!response?.data){
            setShowInfoCaratula(true);
            return showMessageError('','EL NÚMERO DE FORMULARIO NO EXISTE PARA ESTE TIPO DE INSCRIPCIÓN EN SU ORGANIZACIÓN POLÍTICA')
        }

       setDatosCaratula(response?.data[0]);
       setShowInfoCaratula(true);
    }

    if(loadingRequest) return <LoadingSpinner open={loadingRequest}/>

    return (
        <Card className={classes.root}>

            <CardContent>
            {showInfoCarataula && (
                <>
                    {datosCaratula?.idFormulario ? (
                        <>
                         <h1 className={classes.titleAlerta}>Módulo de Registro Electrónico de Inscripción de Candidatos -ICO-</h1>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={4} wrap={"wrap"} style={{margin: '5px 0px'}}  >
                            <Grid item xs={12}>
                                <h3 className={classes.titleAlerta}>Información de la Carátula y Formulario</h3>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <ItemCard  title={'Organización Política'} label={datosCaratula?.objetoOrgnaizacion?.name || ""}/>
                                <ItemCard  title={"Tipo de Formulario"} label={datosCaratula?.tipoInscripcion?.tipoInscripcion || ""}/>
                                <ItemCard  title={"Fecha de creación"} label={functions.dateFormatGeneral(datosCaratula?.fechaCreacionSolicitud || "")}/>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <ItemCard  title={" No. de expediente / formulario"} label={datosCaratula?.idFormulario || ""}/>
                                {datosCaratula?.department && (
                                    <ItemCard  title={'Departamento '} label={datosCaratula?.department?.name || ""}/>
                                )}
                                {datosCaratula?.municipioResidencia && (
                                    <ItemCard  title={'Municipio'} label={datosCaratula?.municipioResidencia?.name || ""}/>
                                )}
                            </Grid>
                        </Grid>
                        </>
                    ) : ( <>
                            <h1 className={classes.titleAlerta}>Módulo de Registro Electrónico de Inscripción de Candidatos -ICO-</h1>
                            <Typography variant='h6' style={{textAlign: 'center', margin: '30px 0px', lineHeight: "27px"}}  > {errorMessages || ""} EN SU ORGANIZACIÓN POLÍTICA </Typography>
                        </>)
                    }
                </>
            )}
            </CardContent>
        </Card>
    )
}

export default ValidarInformacionQR;