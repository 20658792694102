import {combineReducers}  from 'redux';
import menu from './menu';
import signUp from './signUp';
import security from './security';
import representatives from './representatives/representatives';
import general from './generalReducer';

const allReducers = combineReducers({
    menu,
    signUp,
    security,
    representatives,
    general
});

export default allReducers;