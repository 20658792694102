export const actionNames={  
    // MENU SELECTED ITEMS
    SET_MENU_ITEM_GROUP:"SET_MENU_ITEM_GROUP",
    SET_MENU_ITEM:"SET_MENU_ITEM",

    //security
    SET_CURRENT_USER:"SET_CURRENT_USER",
    SET_MENU:"SET_MENU",
    LOGOUT:"LOGOUT",
    REGISTER:"REGISTER",
    GET_DEPARTMENTS: "GET_DEPARTMENTS",
    GET_DISTRICTS: "GET_DISTRICTS",
    GET_MUNICIPALITIES: "GET_MUNICIPALITIES",
    //base64
    DOCUMENTOBASE64_SET_DATA:"DOCUMENTOBASE64_SET_DATA",
    DOCUMENTOBASE64_PARTIDOPOLITICO_SET_DATA:"DOCUMENTOBASE64_PARTIDOPOLITICO_SET_DATA",
    DOCUMENTOBASE64_CONSTANCIACANDIDATOELECCIONPOPULAR_SET_DATA: "DOCUMENTOBASE64_CONSTANCIACANDIDATOELECCIONPOPULAR_SET_DATA",
    DOCUMENTOBASE64_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_SET_DATA: "DOCUMENTOBASE64_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_SET_DATA",

    //PADRON GOZE DERECHOS
    PADRON_GOCEDERECHOS_DELEGACION_SET_DATA:"PADRON_GOCEDERECHOS_DELEGACION_SET_DATA",
    PADRON_SET_DATA:"PADRON_SET_DATA",

    //CVE
    UUID_SET_DATA: "UUID_SET_DATA",
    UUID_SET_DATA_CONSTANCIADECANDIDATOELECCIONPOPULAR: "UUID_SET_DATA_CONSTANCIADECANDIDATOELECCIONPOPULAR",
    UUID_SET_DATA_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA: "UUID_SET_DATA_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA",
    
    //DATA DE CONSULTA PADRON PARTIDO POLITICO
    PADRON_AFILIACION_PARTIDOPOLITICO_SET_DATA:"PADRON_AFILIACION_PARTIDOPOLITICO_SET_DATA",
    PADRON_PARTIDOPOLITICO_DELEGACION_SET_DATA:"PADRON_PARTIDOPOLITICO_DELEGACION_SET_DATA",
    UUID_PARTIDOPOLITICO_SET_DATA: "UUID_PARTIDOPOLITICO_SET_DATA",
    
    //DATA PADRON DE CONSULTA CONSTANCIAS 
    PADRON_CONSTANCIACANDIDATOELECCIONPOPULAR_DELEGACION_SET_DATA:"PADRON_CONSTANCIACANDIDATOELECCIONPOPULAR_DELEGACION_SET_DATA",
    PADRON_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_DELEGACION_SET_DATA:"PADRON_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_DELEGACION_SET_DATA",
    PADRON_CONSTANCIACANDIDATOELECCIONPOPULAR_ORGANIZACIONESPOLITICAS_SET_DATA:"PADRON_CONSTANCIACANDIDATOELECCIONPOPULAR_ORGANIZACIONESPOLITICAS_SET_DATA",
    PADRON_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_ORGANIZACIONESPOLITICAS_SET_DATA:"PADRON_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_ORGANIZACIONESPOLITICAS_SET_DATA",
    
    //CERTIFICADOS 
    CERTIFICACIONDEAFILIACIONAPARTIDOPOLITICO_SET_DATA:"CERTIFICACIONDEAFILIACIONAPARTIDOPOLITICO_SET_DATA",
    CERTIFICACIONDEGOCEDESUSDERECHOSPOLITICOS_SET_DATA: "CERTIFICACIONDEGOCEDESUSDERECHOSPOLITICOS_SET_DATA",

    //CONSTANCIAS
    CONSTANCIACANDIDATOELECCIONPOPULAR_SET_DATA:"CONSTANCIACANDIDATOELECCIONPOPULAR_SET_DATA",
    CONSTANCIACANDIDATOELECCIONPOPULAR_DELEGACION_SET_DATA:"CONSTANCIACANDIDATOELECCIONPOPULAR_DELEGACION_SET_DATA",
    CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_SET_DATA:"CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_SET_DATA",
    CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_DELEGACION_SET_DATA:"CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_DELEGACION_SET_DATA",

    //Solicitud
    SOLICITUD_SET_DATA:"SOLICITUD_SET_DATA",

    //sign up stepper
    SIGNUP_SET_DATA : "SIGNUP_SET_DATA",
    SIGNUP_SET_FILES: "SIGNUP_SET_FILES",
    SIGNUP_SET_FILE_BY_INDEX:  "SIGNUP_SET_FILE_BY_INDEX",
    SIGNUP_SET_STEP:  "SIGNUP_SET_STEP",
    SIGNUP_SET_TOKEN_RECAPCHA:"SIGNUP_SET_TOKEN_RECAPCHA",
    SIGNUP_SET_RECAPTCHA_STATUS:"SIGNUP_SET_RECAPTCHA_STATUS",
    SIGNUP_SET_ARCHIVO_SOLICITUD_GRANDE:"SIGNUP_SET_ARCHIVO_SOLICITUD_GRANDE",
    SIGNUP_SET_ARCHIVO_DPI_FRONTAL_GRANDE:"SIGNUP_SET_ARCHIVO_DPI_FRONTAL_GRANDE",
    SIGNUP_SET_ARCHIVO_DPI_TRASERA_GRANDE:"SIGNUP_SET_ARCHIVO_DPI_TRASERA_GRANDE",
    SIGNUP_SET_ARCHIVO_FOTO_DPI_GRANDE:"SIGNUP_SET_ARCHIVO_FOTO_DPI_GRANDE",
    SIGNUP_SET_TIPO_ARCHIVO_SOLICITUD_DIFERENTE:"SIGNUP_SET_TIPO_ARCHIVO_SOLICITUD_DIFERENTE",
    SIGNUP_SET_TIPO_ARCHIVO_DPI_FRONTAL_DIFERENTE:"SIGNUP_SET_TIPO_ARCHIVO_DPI_FRONTAL_DIFERENTE",
    SIGNUP_SET_TIPO_ARCHIVO_DPI_TRASERA_DIFERENTE:"SIGNUP_SET_TIPO_ARCHIVO_DPI_TRASERA_DIFERENTE",
    SIGNUP_SET_TIPO_ARCHIVO_FOTO_DPI_DIFERENTE:"SIGNUP_SET_TIPO_ARCHIVO_FOTO_DPI_DIFERENTE",
    SIGNUP_SET_NOMBRE_TITULO_ARCHIVO: "SIGNUP_SET_NOMBRE_TITULO_ARCHIVO",

    GET_CANDIDATE_STATE: "GET_CANDIDATE_STATE",
    SET_PRESI_AND_VICE_CANDIDATE_STATE: "SET_PRESI_AND_VICE_CANDIDATE_STATE",
    SET_DEPUTIES_NATIONAL_LIST_STATE: "SET_DEPUTIES_NATIONAL_LIST_STATE",
    SET_DEPUTIES_TO_THE_PARLIAMENT_STATE: "SET_DEPUTIES_TO_THE_PARLIAMENT_STATE",
    GET_DEPARMENT_CANDIDATE_STATE: "GET_DEPARMENT_CANDIDATE_STATE",
    GET_MUNICIPALITY_CANDIDATE_STATE: "GET_MUNICIPALITY_CANDIDATE_STATE",
    CLEAN_DEPARMENT_CANDIDATE_STATE: "CLEAN_DEPARMENT_CANDIDATE_STATE",
    CLEAN_MUNICIPALITY_CANDIDATE_STATE: "CLEAN_MUNICIPALITY_CANDIDATE_STATE",
    GET_CANDIDATE_CIVIC_COMMITTE_STATE: "GET_CANDIDATE_CIVIC_COMMITTE_STATE",
    GET_CANDIDATE_BOARD_OF_DIRECTORS_STATE: "GET_CANDIDATE_BOARD_OF_DIRECTORS_STATE",
    SET_VALUE_TAB_PANEL: "SET_VALUE_TAB_PANEL",
    SET_DATA_CANDIDATE_FOR_REGISTRATION: "SET_DATA_CANDIDATE_FOR_REGISTRATION",

    // ID_FORMS
    SET_ID_FORM_PV: "SET_ID_FORM_PV",
    SET_ID_FORM_DP: "SET_ID_FORM_DP",
    SET_ID_FORM_DN: "SET_ID_FORM_DN",
    SET_ID_FORM_DD: "SET_ID_FORM_DD",
    SET_ID_FORM_CM: "SET_ID_FORM_CM",
    SET_ID_FORM_CM_CCE: "SET_ID_FORM_CM_CCE",
    API_WITH_REDIS: false,
};