import { makeStyles } from '@material-ui/core/styles';

export default makeStyles( () => ({
    gridContainer: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    itemCard: {
      width: '100%',
    },
    root: {
      width: "80%",
      margin: "40px auto",
      border: '1px solid  #cccccc', 
      borderRadius: '20px',
    },
    informationContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 10,
      alignItems: 'center'
    },
    title: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '27px',
      textAlign: 'right',
      marginRight: 10,
    },
    description: {
      fontSize: 16,
      textAlign: 'center',
      padding: '5px 0px 5px 0',
      marginLeft: 27,
      border: '2px solid #e0e0e0',
      borderRadius: '16px',
      width: '75%',
      ['@media (max-width: 960px)']: {
        width: '90%',
      }
    },
    icon: {
      fontSize: '28px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    constanciesPDF: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      marginBottom: 10,
      marginTop: 10,
      width: '100%',
    },
    titleAlerta: {
      fontWeight: 'bold',
      fontSize: 27,
      lineHeight: '27px',
      textAlign: 'center',
      marginRight: 10,
    },
    colorComponente:{
      backgroundColor: 'white',
      alignItems: "center",
      borderRadius: 20,
      flexFlow: "row-wrap",

  },
  lineaDegradadaBottom:{
      position: "relative",
      bottom: 0,
      left: 0,
      width: "100%",
      height: 4,
      background: `linear-gradient(344deg, rgba(2,0,36,1) 0%, rgba(0,107,251,0.8) 45%, rgba(0,212,255,1) 100%)`,
  },
}));
