
const initialState={
    files: [
        {
            id: 1,
            imagePath: "/assets/simboloPdf.svg",
            text: "Inserte o seleccione el documento PDF escaneado.",
            type:".pdf",
            titulo: 'Archivo de solicitud demasiado grande, tamaño maximo 25 MB',
            base64: null
        },
        {
            id: 2,
            imagePath: "/assets/DPIFRONTAL.svg",
            text: "Inserte fotografía anversa de su DPI jpg, jpeg, png, con un tamaño máximo 25mb",
            type:".jpg, .jpeg, .png",
            titulo: 'Archivo de DPI de la parte frontal demasiado grande, tamaño maximo 25 MB',
            base64: null
        },
        {
            id: 3,
            imagePath: "/assets/DPIREVERSO.svg",
            text: "Inserte fotografía reversa de su DPI, jpg, jpeg, png, con un tamaño máximo 25mb",
            type:".jpg, .jpeg, .png",
            titulo: 'Archivo de DPI de la parte trasera demasiado grande, tamaño maximo 25 MB',
            base64: null
        },
        {
            id: 4,
            imagePath: "/assets/DPIFOTO.svg",
            text: "Tomese una fotografía de rostro junto con DPI original e insertela aqui, jpg, jpeg, png, con un tamaño máximo 25mb",
            type:".jpg, .jpeg, .png",
            titulo: 'Archivo de fotografía con su DPI demasiado grande, tamaño maximo 25 MB',
            base64: null
        },
    ], 
    step: 0,
    
    data:{
        cui:"",birthDate: new Date(),firstName:"",
        secondName:"",thirdName:"",firstLastName:"",
        secondLastName:"",marriedLastName:"",
        email:"",city_id:0, state_id:0,
    },
    tokenRecaptcha:null,
   RecaptchaStatus:false,
    ArchivoSolicitudGrande: false,
    ArchivoDPIFrontalGrande: false,
    ArchivoDPITraseraGrande: false,
    ArchivoFotoDPIGrande: false,
    TipoArchivoSolicitudDiferente:false,
    TipoArchivoDPIFrontalDiferente:false,
    TipoArchivoDPITraseroDiferente:false,
    TipoArchivoFotoDPIDiferente:false,
    nombreTituloArchivo:"",
};

const reducer = (state = initialState, action )=>{
    switch(action.type){
        case "SIGNUP_SET_DATA":
            let signupDataState = Object.assign({}, state);
            signupDataState.data.cui= action.state.data.dpi.value;
            signupDataState.data.birthDate= action.state.data.birthDate.value;
            signupDataState.data.firstName = action.state.data.firstName.value.toUpperCase();
            signupDataState.data.secondName = action.state.data.secondName.value.toUpperCase();
            signupDataState.data.thirdName = action.state.data.thirdName.value.toUpperCase();
            signupDataState.data.firstLastName = action.state.data.firstLastName.value.toUpperCase();
            signupDataState.data.secondLastName = action.state.data.secondLastName.value.toUpperCase();
            signupDataState.data.marriedLastName = action.state.data.marriedLastName.value.toUpperCase();
            signupDataState.data.email = action.state.data.email.value.toUpperCase();
            signupDataState.data.reEmail = action.state.data.email.value.toUpperCase();
            signupDataState.data.city_id = action.state.data.city_id.value;
            signupDataState.data.state_id = action.state.data.state_id.value;
            return signupDataState;
        case "SIGNUP_SET_FILES":
            let signupFilesState = Object.assign({}, state);
            signupFilesState.files=action.state.files;
            return signupFilesState;
        case "SIGNUP_SET_FILE_BY_INDEX":
            let signupFileByIndexState = Object.assign({}, state);
            signupFileByIndexState.files.forEach((item,index)=>{
                if (item.id===action.state.index)  signupFileByIndexState.files[index].base64=action.state.base64;
            });
            return signupFileByIndexState;
        case "SIGNUP_SET_STEP":
            let signupStepState = Object.assign({}, state);
            signupStepState.step=action.state.step;
            return signupStepState;
        case "SIGNUP_SET_TOKEN_RECAPCHA":
            var signupRecapchaState = Object.assign({}, state);
            signupRecapchaState.tokenRecaptcha=action.state.tokenRecaptcha;
            return signupRecapchaState;
        case "SIGNUP_SET_RECAPTCHA_STATUS":
            var signupRecapchaState = Object.assign({}, state);
            signupRecapchaState.RecaptchaStatus=action.state.RecaptchaStatus;
            return signupRecapchaState;
        case "SIGNUP_SET_ARCHIVO_SOLICITUD_GRANDE":
            let signupArchivoSolicitudGrande = Object.assign({}, state);
            signupArchivoSolicitudGrande.ArchivoSolicitudGrande=action.state.ArchivoSolicitudGrande;
            return signupArchivoSolicitudGrande;            
        case "SIGNUP_SET_ARCHIVO_DPI_FRONTAL_GRANDE":
            let signupArchivoDPIFrontalGrande = Object.assign({}, state);
            signupArchivoDPIFrontalGrande.ArchivoDPIFrontalGrande=action.state.ArchivoDPIFrontalGrande;
            return signupArchivoDPIFrontalGrande;            
        case "SIGNUP_SET_ARCHIVO_DPI_TRASERA_GRANDE":
            let signupArchivoDPITraseraGrande = Object.assign({}, state);
            signupArchivoDPITraseraGrande.ArchivoDPITraseraGrande=action.state.ArchivoDPITraseraGrande;
            return signupArchivoDPITraseraGrande;            
        case "SIGNUP_SET_ARCHIVO_FOTO_DPI_GRANDE":
            let signupArchivoFotoDPIGrande = Object.assign({}, state);
            signupArchivoFotoDPIGrande.ArchivoFotoDPIGrande=action.state.ArchivoFotoDPIGrande;
            return signupArchivoFotoDPIGrande;            
        case "SIGNUP_SET_TIPO_ARCHIVO_SOLICITUD_DIFERENTE":
            let signupTipoArchivoSolicitudDiferente = Object.assign({}, state);
            signupTipoArchivoSolicitudDiferente.TipoArchivoSolicitudDiferente=action.state.TipoArchivoSolicitudDiferente;
            return signupTipoArchivoSolicitudDiferente;            
        case "SIGNUP_SET_TIPO_ARCHIVO_DPI_FRONTAL_DIFERENTE":
            let signupTipoArchivoDPIFrontalDiferente = Object.assign({}, state);
            signupTipoArchivoDPIFrontalDiferente.TipoArchivoDPIFrontalDiferente=action.state.TipoArchivoDPIFrontalDiferente;
            return signupTipoArchivoDPIFrontalDiferente;            
        case "SIGNUP_SET_TIPO_ARCHIVO_DPI_TRASERA_DIFERENTE":
            let signupTipoArchivoDPITraseroDiferente = Object.assign({}, state);
            signupTipoArchivoDPITraseroDiferente.TipoArchivoDPITraseroDiferente=action.state.TipoArchivoDPITraseroDiferente;
            return signupTipoArchivoDPITraseroDiferente;            
        case "SIGNUP_SET_TIPO_ARCHIVO_FOTO_DPI_DIFERENTE":
            let signupTipoArchivoFotoDPIDiferente = Object.assign({}, state);
            signupTipoArchivoFotoDPIDiferente.TipoArchivoFotoDPIDiferente=action.state.TipoArchivoFotoDPIDiferente;
            return signupTipoArchivoFotoDPIDiferente;            
        case "SIGNUP_SET_NOMBRE_TITULO_ARCHIVO":
            let signupNombreTituloArchivo = Object.assign({}, state);
            signupNombreTituloArchivo.nombreTituloArchivo=action.state.nombreTituloArchivo;
            return signupNombreTituloArchivo;   
        default:
            return state;
    }
}

export default reducer;